import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './../pages/dashboardAdmin.css';

const OrganisationModal = ({ show, handleClose, handleChange, handleSubmit, form }) => {
   
    const domainesActivite = [
        'Agroalimentaire',
        'Banque / Assurance',
        'Bois / Papier / Carton / Imprimerie',
        'BTP / Matériaux de construction',
        'Chimie / Parachimie',
        'Commerce / Négoce / Distribution',
        'Édition / Communication / Multimédia',
        'Électronique / Électricité',
        'Études et conseils',
        'Industrie pharmaceutique',
        'Informatique / Télécoms',
        'Machines et équipements / Automobile',
        'Métallurgie / Travail du métal',
        'Plastique / Caoutchouc',
        'Services aux entreprises',
        'Textile / Habillement / Chaussure',
        'Transports / Logistique'
    ];

    return (
        <Modal show={show} onHide={handleClose} className="Modal">
            <Modal.Header closeButton>
                <Modal.Title>{form.id ? 'Modifier l\'organisation' : 'Créer une organisation'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="auth-form" onSubmit={handleSubmit}>
                    <Form.Group className="form-group">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control 
                            className="form-control" 
                            type="text" 
                            name="nom" 
                            value={form.nom} 
                            onChange={handleChange} 
                            required 
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Adresse</Form.Label>
                        <Form.Control 
                            className="form-control" 
                            type="text" 
                            name="adresse" 
                            value={form.adresse} 
                            onChange={handleChange} 
                            required 
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Taille</Form.Label>
                        <Form.Control 
                            className="form-control" 
                            type="number" 
                            name="taille" 
                            value={form.taille} 
                            onChange={handleChange} 
                            required 
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Domaine d'activité</Form.Label>
                        <Form.Select 
                            className="form-control" 
                            name="domaine_act" 
                            value={form.domaine_act} 
                            onChange={handleChange} 
                            required
                        >
                            <option value="">Sélectionnez un domaine</option>
                            {domainesActivite.map(domaine => (
                                <option key={domaine} value={domaine}>
                                    {domaine}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group">
                    <Form.Label>Type</Form.Label>
                        <Form.Control
                            as="select"
                            name="type"
                            value={form.type}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Sélectionner un type</option>
                            <option value="Entreprise privée (artisanale, industrielle, de service)">Entreprise privée (artisanale, industrielle, de service)</option>
                            <option value="Organisation publique (centrale, locale, sécurité sociale)">Organisation publique (centrale, locale, sécurité sociale)</option>
                            <option value="Organisation à but non lucratif (association, syndicat)">Organisation à but non lucratif (association, syndicat)</option>
                        </Form.Control>
                    </Form.Group>
                    <Button type="submit" className="btn-solid w-100 text-center mt-3">
                        Enregistrer
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default OrganisationModal;
