import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './switch.css';
import { Modal, Button, Table } from 'react-bootstrap';
import config from '../config';
const VersionList = ({ onVersionActivated }) => {
    const [versions, setVersions] = useState([]);
    const [activeVersionId, setActiveVersionId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [categoriesMereDetails, setCategoriesMereDetails] = useState([]);  
    const [categoriesDetails, setCategoriesDetails] = useState([]);  


    const fetchVersions = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}api/versions`);
            setVersions(response.data);

            const activeResponse = await axios.get(`${config.apiUrl}api/version/active`);
            setActiveVersionId(activeResponse.data.id);
        } catch (error) {
            console.error("Error fetching versions:", error);
        }
    };

    useEffect(() => {
        fetchVersions();
    }, []);

  
    const handleSwitchChange = async (versionId, isActive) => {
        try {

            if (isActive) {

                if (activeVersionId && activeVersionId !== versionId) {
                    await axios.put(`${config.apiUrl}api/version/${activeVersionId}/activate`, { active: false });
                }


                await axios.put(`${config.apiUrl}api/version/${versionId}/activate`, { active: true });
                setActiveVersionId(versionId);


                const updatedVersions = versions.map(version =>
                    version.id === versionId ? { ...version, active: true } : { ...version, active: false }
                );
                setVersions(updatedVersions);
                onVersionActivated();
            } else {
                console.log("Impossible de désactiver la dernière version active sans en activer une autre.");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la version:", error);
        }
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}-${month}-${year}  ${hours}:${minutes}`;
    };



    const handleShowModal = async (versionId) => {
        const version = versions.find(v => v.id === versionId);
        setSelectedVersion(version);

        try {
            const responseCM = await axios.get(`${config.apiUrl}api/categories_mere/${versionId}`);
            setCategoriesMereDetails(responseCM.data);
            const responseC = await axios.get(`${config.apiUrl}api/categories/${versionId}`);
            setCategoriesDetails(responseC.data);
        } catch (error) {
            console.error("Erreur lors du chargement des détails des catégories :", error);
        }

        setShowModal(true);
    };
    const handleDownloadExcel = async (versionId) => {
        try {
            const response = await axios.get(`${config.apiUrl}api/version/${versionId}/download_excel`, {
                responseType: 'blob', 
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `version_${versionId}_audit.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier Excel :", error);
        }
    };
    const organizedCategories = categoriesMereDetails.map((categorieMere) => {
        return {
            ...categorieMere,
            sousCategories: categoriesDetails.filter(categorie => categorie.categorie_mere_id === categorieMere.id)
        };
    });

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedVersion(null);
    };

    return (
        <div>

            <div className="container">
                <ul>
                    {versions.map(version => (
                        <li key={version.id}>
                            <div className=" ">
                                <div className="col-12">
                                    <div className="blog-listing" style={{ width: '100%' }}>
                                        <div className="blog-content">
                                            <a data-cursor="pointer" className="main-title" href="blog-details.html">
                                                Version {version.numero} {version.active}
                                            </a>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={version.active}


                                                    onChange={() => handleSwitchChange(version.id, !version.active)}
                                                    disabled={version.active && versions.filter(v => v.active).length === 1}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                            <ul>
                                                <li>
                                                    <i className="iconsax" icon-name="calendar-add" data-icon="calendar-add"></i>
                                                    {formatDate(version.date_creation)}
                                                </li>
                                            </ul>
                                            <a
                                                data-cursor="pointer"
                                                className="btn-arrow"
                                                href="#"
                                                onClick={() => handleShowModal(version.id)}
                                            >
                                                <div className="icon-arrow">
                                                    <i className="iconsax" icon-name="arrow-up"></i>
                                                </div>
                                                plus d'information
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Informations sur la version</Modal.Title>
                    <button style={{marginLeft : '45%'}}
                                className="btn btn-secondary"
                                onClick={() => handleDownloadExcel(selectedVersion.id)}
                              >
                                <i className="ri-file-excel-2-fill"></i>
                              </button>
                    
                </Modal.Header>
                <Modal.Body>
                    {selectedVersion ? (
                        <div>
                            <p><strong>Numéro de version:</strong> {selectedVersion.numero}</p>
                            <p><strong>Date de création:</strong> {formatDate(selectedVersion.date_creation)}</p>
                            <p><strong>Active:</strong> {selectedVersion.active ? 'Oui' : 'Non'}</p>
                            {organizedCategories.length > 0 ? (
                                <Table bordered >
                                    <thead>
                                        <tr>
                                            <th>Catégorie Mère</th>
                                            <th>Sous-Catégorie</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {organizedCategories.map((categorieMere) => (
                                            <React.Fragment key={categorieMere.id}>
                                                <tr>
                                                    <td><strong>{categorieMere.nom}</strong></td>
                                                    <td>
                                                        {categorieMere.sousCategories.length > 0 ? (
                                                            <Table size="sm" borderless>
                                                                <tbody>
                                                                    {categorieMere.sousCategories.map((sousCategorie) => (
                                                                        <tr key={sousCategorie.id}>
                                                                            <td>{sousCategorie.nom}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        ) : (
                                                            <p>Aucune sous-catégorie disponible.</p>
                                                        )}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>Chargement des catégories...</p>
                            )}
                        </div>
                    ) : (
                        <p>Chargement des informations...</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default VersionList;
