import React from "react";
import { Link ,useNavigate} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import "./dashboardAdmin.css";
import axios from "axios";
import UserModal from "./../modal/UserModal";
import OrganisationModal from "./../modal/OrganisationModal";
import ImportFileModal from "./../modal/ImportFileModal";
import VersionList from './VersionList';
import config from '../config';
import Statistics from './stat';
import OrganisationStats from './OrganisationStats';
import LogoutButton from './../button/LogoutButton';
import { Modal, Button, Form } from 'react-bootstrap';
import introJs from 'intro.js';

function DashboardAdmin() {
  const navigate = useNavigate();
  const role = localStorage.getItem('userRole');
  useEffect(() => {
    if (role !== 'admin') {
      navigate('/'); 
    }
  }, [role, navigate]);
  const [users, setUsers] = useState([]);
  const [form, setForm] = useState({
    login: "",
    mdp: "",
    organisation: "",
    role: "",
    email: "",
  });
  const [show, setShow] = useState(false);

  const targetDivRef = useRef(null);
  useEffect(() => {
    /**=====================
       Custom tab toggle 
    ==========================**/

    const subscriptionBtn = document.getElementById("subscriptionBtn");
    if (subscriptionBtn) {
      subscriptionBtn.addEventListener("click", function () {
        const divs = document.querySelector("[data-bs-target='#subscription']");
        if (divs) {
          divs.click();
        }
      });
    }

    /**===========================
         li active & change content
    ===========================**/

    const parentElement = document.getElementById("innerLink");
    if (parentElement) {
      const listItems = parentElement.getElementsByTagName("li");

      for (let i = 0; i < listItems.length; i++) {
        listItems[i].addEventListener("click", function (event) {
          const siblings = this.parentNode.children;
          for (let j = 0; j < siblings.length; j++) {
            siblings[j].classList.remove("active");
          }

          this.classList.add("active");
          // change content
          const attributeValue = event.target.getAttribute("data-title");
          if (targetDivRef.current) {
            targetDivRef.current.textContent = attributeValue;
          }
        });
      }
    }
    return () => {
      if (subscriptionBtn) {
        subscriptionBtn.removeEventListener("click", () => { });
      }
    };
  }, []);

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px',
    fontSize: '22px',
    backgroundColor: '#565e64',
    color: '#20c997',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const iconStyle = {
    marginRight: '10px',
  };
  const fetchSynthese = async (idQuestionnaire) => {
    try {
      const response = await axios.get(`${config.apiUrl}api/audit/synthese/${idQuestionnaire}`);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
      return [];
    }
  };
 


 

  /////// gestion utilisateurs////////
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}api/utilisateurs`
      );
      setUsers(response.data);
      //   setLoading(false);
    } catch (err) {
      //   setError('Error fetching users');
      //   setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.id) {
      await axios.put(
        `${config.apiUrl}api/utilisateurs/${form.id}`,
        form
      );
    } else {
      await axios.post(`${config.apiUrl}api/utilisateurs`, form);
    }
    fetchUsers();
    setForm({
      id: "",
      login: "",
      mdp: "",
      organisation: "",
      role: "",
      email: "",
    });
  };

  const handleEdit = (user) => {
    setForm(user);
    setShow(true);
  };
  const handleClose = () => {
    setForm({ login: "", mdp: "", organisation: "", role: "", email: "" });
    setShow(false);
  };
  const handleDelete = async (id) => {
    await axios.delete(`${config.apiUrl}api/utilisateurs/${id}`);
    fetchUsers();
  };



  /////// gestion organisations////////
  const [showORG, setShowORG] = useState(false);
  const [formORG, setFormORG] = useState({
    nom: "",
    adresse: "",
    taille: "",
    domaine_act: "",
    type: "",
  });
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    fetchOrganisations();
  }, []);

  const fetchOrganisations = async () => {
    const response = await axios.get(`${config.apiUrl}api/organisations`);
    setOrganisations(response.data);
  };

  const handleShowORG = () => setShowORG(true);
  const handleCloseORG = () => setShowORG(false);
  const handleChangeORG = (e) => {
    const { name, value } = e.target;
    setFormORG({
      ...formORG,
      [name]: value,
    });
  };

  const handleSubmitORG = async (e) => {
    e.preventDefault();
    if (formORG.id) {
      await axios.put(
        `${config.apiUrl}api/organisations/${formORG.id}`,
        formORG
      );
    } else {
      await axios.post(`${config.apiUrl}api/organisations`, formORG);
    }
    fetchOrganisations();
    handleCloseORG();
  };

  const handleEditORG = (organisation) => {
    setFormORG(organisation);
    handleShowORG();
  };

  const handleDeleteORG = async (id) => {
    await axios.delete(`${config.apiUrl}api/organisations/${id}`);
    fetchOrganisations();
  };



  ///////gestion questionnaire/////////

  const [showModalIMP_FILE, setShowModalIMP_FILE] = useState(false);
  const [fileIMP_FILE, setFileIMP_FILE] = useState(null);

  const handleFileChangeIMP_FILE = (event) => {
    setFileIMP_FILE(event.target.files[0]);
  };

  const handleCloseModalIMP_FILE = () => setShowModalIMP_FILE(false);

  const handleOpenModalIMP_FILE = () => setShowModalIMP_FILE(true);

  const handleSubmitIMP_FILE = async (event) => {
    event.preventDefault();
    const formDataIMP_FILE = new FormData();
    formDataIMP_FILE.append('file', fileIMP_FILE);

    try {
      const response = await axios.post(`${config.apiUrl}api/upload`, formDataIMP_FILE, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert(`Fichier importé avec succès : ${response.data.message}`);
      //onQuestionnaireUploaded();
    } catch (error) {
      alert(`Erreur lors de l'importation : ${error.response ? error.response.data.error : error.message}`);
    }
    handleCloseModalIMP_FILE();
  };


  const [info, setInfo] = useState({ num_questions: 0, num_categories: 0, num_categories_mere: 0 });
 const fetchData = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}api/info`);
      setInfo(response.data);
    } catch (error) {
      console.error('Error fetching info:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleVersionActivated = () => {
    fetchData();
  };
  const handleFetchData = () => {
    fetchData();
  };



  const handleDownload = async () => {
    try {
      const response = await axios.post(`${config.apiUrl}api/merge-json-to-excel`, {}, {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'output.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };


  ////// questionnaire par organisation ///////
  const [selectedOrganisation, setSelectedOrganisation] = useState({ nom: '', questionnaires: [] });
  const [showModal, setShowModal] = useState(false);
  const [questionnaires, setQuestionnaires] = useState([]);

  useEffect(() => {
    axios.get(`${config.apiUrl}api/QuestionnaireByOrg`)
      .then(response => {
        setQuestionnaires(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, []);

  const handleViewORG = (organisation) => {
    setSelectedOrganisation({
      nom: organisation.nom,
      questionnaires: questionnaires.filter(q => q.nom_organisation === organisation.nom)
    });
    console.log(selectedOrganisation.questionnaires)
    setShowModal(true);
  };

  const handleCloseModalORG = () => {
    setShowModal(false);
    setSelectedOrganisation({ nom: '', questionnaires: [] });
  };

  const renderStatus = (etat) => {
    if (etat === 100) {
      return { label: 'Terminé', className: 'b' };
    } else if (etat > 0) {
      return { label: 'En cours', className: 'b' };
    } else {
      return { label: 'Non commencé', className: 'b' };
    }
  };

  const groupByOrganisation = (questionnaires) => {
    const grouped = new Map();

    questionnaires.forEach((questionnaire) => {
      const orgName = questionnaire.nom || 'Non spécifié';

      if (!grouped.has(orgName)) {
        grouped.set(orgName, []);
      }

      grouped.get(orgName).push(questionnaire);
    });

    return grouped;
  };

  const groupedQuestionnaires = groupByOrganisation(questionnaires);

  const downloadResults = async (id) => {
    try {
      const response = await axios.get(`${config.apiUrl}api/audit/download_results/${id}`, {
        responseType: 'blob',
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'resultats_questionnaire.xlsx');  // Nom du fichier
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  return (
    <div>
      <section className="chatting-wrapper pt-0">
        <nav className="navbar navbar-expand-md p-0">
          <button
            className="navbar-toggler d-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainnavbarNav"
            aria-controls="mainnavbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="iconsax" data-icon="text-align-justify"></i>
          </button>
          <div className="collapse navbar-collapse" id="mainnavbarNav">
            <div className="menu-panel">
              <button
                data-bs-toggle="collapse"
                data-bs-target="#mainnavbarNav"
                className="mainnav-close d-block d-md-none"
              >
                <i
                  className="iconsax"
                  icon-name="close-circle"
                  data-icon="close-circle"
                ></i>
              </button>
              <Link to="/" className="logo-icon d-none d-md-flex">
                <img
                  src="../assets/svg/logo-icon.svg"
                  className="img-fluid"
                  alt="Logo de l'entreprise"
                />
              </Link>

              <ul
                className="nav nav-tabs menu-wrapper"

                id="myTab"
                role="tablist"
              >
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  data-bs-title="Chat"
                  className="nav-item"
                  role="presentation"
                >
                  <button
                    className="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#chat"
                    type="button"
                    role="tab"
                    aria-controls="chat"
                    aria-selected="true"
                  >
                    <i
                      className="iconsax ri-chat-poll-fill"
                      
                    ></i>
                    <span>Chat</span>{" "}
                  </button>
                </li>
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  data-bs-title="History"
                  className="nav-item"
                  role="presentation"
                >
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#history"
                    type="button"
                    role="tab"
                    aria-controls="history"
                    aria-selected="false"
                  >
                    <i className=" iconsax ri-user-fill"></i>
                    <span>gestion des utilisateurs</span>{" "}
                  </button>
                </li>
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  data-bs-title="Subscription"
                  className="nav-item"
                  role="presentation"
                >
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#subscription"
                    type="button"
                    role="tab"
                    aria-controls="subscription"
                    aria-selected="false"
                  >
                    <i className="iconsax ri-building-line"></i>
                    <span>Subscription</span>
                  </button>
                </li>
          
                
                <li data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Settings" className="nav-item"
                  role="presentation">
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#settings" type="button"
                    role="tab" aria-controls="settings" aria-selected="false"><i className="iconsax"
                      data-icon="setting-1" icon-name="bar-graph-1"></i><span>statistique</span></button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="tab-content">
          <div  className="tab-pane fade show active"
            id="chat"
            role="tabpanel"
            aria-labelledby="chat"
            tabIndex="0"
          >

            <div className="main-wrapper p-0">
              <div className="fixed-header">
                <div className="d-flex align-items-center gap-2">
                  <button className="navbar-toggler d-md-none d-block" type="button" data-bs-toggle="collapse"
                    data-bs-target="#mainnavbarNav" aria-controls="mainnavbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <i className="iconsax" data-icon="text-align-justify"></i>
                  </button>
                  <Link to="/" className="logo-icon d-flex d-md-none">
                    <img src="../assets/svg/logo-icon.svg" className="img-fluid" />
                  </Link>
                  <h3>Backoffice</h3>
                </div>
                <LogoutButton />
              </div>
              <div className="main-section">

                <div className="container card p-0">
                  <div className="card-header">
                    <h3 className="text-white">Gestion des Questionnaires :</h3>
                  </div>

                  <div className="card-body px-sm-4 px-3">
                    <div className="row justify-content-center">
                      {
                        <div className="col-xl-4 col-md-6 col-12">
                          <div className="card inner-card">
                            <div className="card-header">
                              <i
                                className="iconsax"
                                data-icon="document-download"
                                icon-name="document-download"
                                style={{ fontSize: "26px" }}
                              ></i>
                              <h4 className="text-white mb-0">
                                Télécharger en Excel
                              </h4>
                            </div>
                            <div className="card-body">
                              <h3>Questionnaire Actuel</h3>
                              <ul>
                                <li>{info.num_questions} questions</li>
                                <li>{info.num_categories} catégories</li>
                                <li>{info.num_categories_mere} catégories mères</li>
                              </ul>
                              <button className="select-plan" onClick={handleDownload}>
                                Télécharger
                              </button>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="col-xl-4 col-md-6 col-12">
                        <div className="card inner-card">
                          <div className="card-header">
                            <i
                              className="iconsax"
                              data-icon="document-upload"
                              icon-name="document-upload"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <h4 className="text-white mb-0">
                              Importer un Questionnaire
                            </h4>
                          </div>
                          <div className="card-body">
                            <h3>Nouveau Questionnaire</h3>
                            <ul>
                              <li>
                                Validez que chaque question appartient à une
                                catégorie et une sous-catégorie valides.
                              </li>
                              <li>
                                Le fichier .xlsx sera converti dans un format
                                utilisable par le système.
                              </li>
                            </ul>
                            <div>

                            </div>
                            <button className="select-plan" onClick={handleOpenModalIMP_FILE}>
                              Importer
                            </button>
                            <ImportFileModal
                              show={showModalIMP_FILE}
                              handleClose={handleCloseModalIMP_FILE}
                              handleFileChange={handleFileChangeIMP_FILE}
                              handleSubmit={handleSubmitIMP_FILE}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container card p-0" style={{ marginTop: '10px' }}>
                  <div className="card-header">
                    <h3 className="text-white">Gestion des versions :</h3>
                  </div>
                  <div className="card-body px-sm-4 px-3">
                    <div className="row justify-content-center">
                      <VersionList onVersionActivated={handleVersionActivated} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="tab-pane fade"
            id="history"
            role="tabpanel"
            aria-labelledby="history"
            tabIndex="0"
          >
            <div className="main-wrapper p-0">
              <div className="fixed-header">
                <div className="d-flex align-items-center gap-2">
                  <button
                    className="navbar-toggler d-md-none d-block"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#mainnavbarNav"
                    aria-controls="mainnavbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i
                      className="iconsax"
                      data-icon="text-align-justify"
                      icon-name="text-align-justify"
                    ></i>
                  </button>
                  <Link to="/" className="logo-icon d-flex d-md-none">
                    <img
                      src="../assets/svg/logo-icon.svg"
                      className="img-fluid"
                    />
                  </Link>
                  <h3>Gestion des utilisateurs</h3>
                </div>
                <LogoutButton />
              </div>
              <div className="main-section">
                <div className="container card p-0">
                  <div className="card-header">
                    <h3 className="text-white">Liste des utilisateurs</h3>
                  </div>
                  <div className="card-body px-sm-4 px-3">
                    <button
                      className="btn btn-secondary"
                      onClick={() => setShow(true)}
                    >
                      <i className="ri-add-circle-fill"></i>
                    </button>
                    <UserModal
                      show={show}
                      handleClose={handleClose}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      form={form}
                      organisations={organisations}
                    />
                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Login</th>
                          <th>Organisation</th>
                          <th>Role</th>
                          <th>Email</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => (
                          <tr key={user.id}>
                            <td>{index + 1}</td>{" "}
                            <td>{user.login}</td>
                            <td>{user.organisation}</td>
                            <td>{user.role}</td>
                            <td>{user.email}</td>
                            <td>
                              <button
                                className="btn btn-secondary"
                                onClick={() => handleEdit(user)}
                              >
                                <i className="ri-edit-line"></i>
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={() => handleDelete(user.id)}
                              >
                                <i className="ri-delete-bin-fill"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="tab-pane fade"
            id="subscription"
            role="tabpanel"
            aria-labelledby="subscription"
            tabIndex="0"
          >
            <div className="main-wrapper p-0">
              <div className="fixed-header">
                <div className="d-flex align-items-center gap-2">
                  <button
                    className="navbar-toggler d-md-none d-block"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#mainnavbarNav"
                    aria-controls="mainnavbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="iconsax" data-icon="text-align-justify"></i>
                  </button>
                  <Link to="/" className="logo-icon d-flex d-md-none">
                    <img
                      src="../assets/svg/logo-icon.svg"
                      className="img-fluid"
                    />
                  </Link>
                  <h3>Gestion des organisations</h3>
                </div>
                <LogoutButton />
              </div>
              <div className="main-section">
                <div className="container card p-0">
                  <div className="card-header">
                    <h3 className="text-white">Liste des organisations</h3>
                  </div>
                  <div className="card-body px-sm-4 px-3">
                    <button
                      className="btn btn-secondary"
                      onClick={handleShowORG}
                    >
                      <i className="ri-add-circle-fill"></i>
                    </button>
                    <OrganisationModal
                      show={showORG}
                      handleClose={handleCloseORG}
                      handleChange={handleChangeORG}
                      handleSubmit={handleSubmitORG}
                      form={formORG}
                    />
                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Nom</th>
                          <th>Adresse</th>
                          <th>Taille</th>
                          <th>Industrie</th>
                          <th>Type</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {organisations.map((organisation, index) => (
                          <tr key={organisation.id}>
                            <td>{index + 1}</td>
                            <td>{organisation.nom}</td>
                            <td>{organisation.adresse}</td>
                            <td>{organisation.taille}</td>
                            <td>{organisation.domaine_act}</td>
                            <td>{organisation.type}</td>
                            <td>
                              <button
                                className="btn btn-secondary"
                                onClick={() => handleEditORG(organisation)}
                              >
                                <i className="ri-edit-line"></i>
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={() => handleDeleteORG(organisation.id)}
                              >
                                <i className="ri-delete-bin-fill"></i>
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={() => handleViewORG(organisation)}
                              >
                                < i class="ri-eye-line"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="settings" tabIndex="0">
            <div className="main-wrapper p-0">
              <div className="fixed-header">
                <div className="d-flex align-items-center gap-2">
                  <button className="navbar-toggler d-md-none d-block" type="button" data-bs-toggle="collapse"
                    data-bs-target="#mainnavbarNav" aria-controls="mainnavbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <i className="iconsax" data-icon="text-align-justify"></i>
                  </button>
                  <Link to="/" className="logo-icon d-flex d-md-none">
                    <img src="../assets/svg/logo-icon.svg" className="img-fluid" />
                  </Link>
                  <h3>stat</h3>
                </div>
                <LogoutButton />
              </div>
              <div className="container-fluid">
                <div className="header d-flex justify-content-between align-items-center">

                  <h3>Statistiques</h3>

                </div>
                <div className="main-section d-flex gap-4 flex-column">
                  <div className="container card p-0">
                    <div className="card-header">
                    <h3 className="text-white">Statistiques par Domaine d'Organisation</h3>
                    </div>
                    <div className="card-body px-sm-4 px-3">
                      <OrganisationStats />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal rating-modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
       
      </div>


      <Modal show={showModal} onHide={handleCloseModalORG} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Les questionnaires de : {selectedOrganisation.nom}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {selectedOrganisation.questionnaires && selectedOrganisation.questionnaires.length > 0 ? (
              selectedOrganisation.questionnaires.map((questionnaire, index) => (
                <div
                  key={index}
                  className="col-md-4 mb-3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="card shadow-sm" style={{ borderRadius: '12px' }}>
                    <div className="card-body">
                      <h5 className="card-title" style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        Version {questionnaire.version || 'Non spécifiée'}
                      </h5>
                      <p className="card-text" style={{ fontSize: '1.1rem', marginBottom: '0.8rem' }}>
                        <strong>Status:</strong>{" "}
                        <div
                          className={renderStatus(questionnaire.etat_questionnaire).className}
                          style={{
                            fontSize: '0.9rem',
                            fontWeight: '600',
                            color: questionnaire.etat_questionnaire === 'En cours' ? '#FFFFFF !important' : '#6c757d !important',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            backgroundColor: questionnaire.etat_questionnaire === 'En cours' ? '#3BEDB2 !important' : '#e9ecef !important'
                          }}
                        >
                          {renderStatus(questionnaire.etat_questionnaire).label}
                        </div>
                      </p>
                      <p className="card-text" style={{ fontSize: '1.1rem' }}>
                        <strong>Percentage:</strong> {questionnaire.etat_questionnaire}%
                      </p>
                      <p className="card-text" style={{ fontSize: '1.1rem' }}>
                        <strong>Répondant:</strong> {questionnaire.utilisateur || 'Non spécifié'}
                      </p>
                      <a
                        href="#"
                        className={`btn`}
                        style={{
                          fontSize: '1rem',
                          padding: '8px 20px',
                          backgroundColor: questionnaire.etat_questionnaire === 0 ? '#6c757d' : '#3BEDB2',
                          color: '#fff',
                          borderRadius: '8px',
                        }}
                        onClick={() => downloadResults(questionnaire.questionnaire_id)}
                        disabled={questionnaire.etat_questionnaire === 0}
                      >
                        Télécharger les résultats
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">Aucun questionnaire disponible.</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalORG}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>


    </div>
  );
}

export default DashboardAdmin;
