import React, { useState, useEffect, version } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config'
import Footer from './../components/Footer'
/*import './public/assets/css/vendors/bootstrap.css';
import './assets/css/vendors/remixicon.css';
import './assets/css/vendors/iconsax.css';
import './assets/css/vendors/aos.css';
import './assets/css/vendors/swiper-bundle.min.css';
import './assets/css/landing.css';*/

function Welcome() {
    useEffect(() => {

        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.async = true;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };


        loadScript('assets/js/bootstrap.bundle.min.js')
            .then(() => loadScript('assets/js/swiper-bundle.min.js'))
            .then(() => loadScript('assets/js/custom-slider.js'))
            .then(() => loadScript('assets/js/aos.js'))
            .then(() => loadScript('assets/js/iconsax.js'))
            //  .then(() => loadScript('assets/js/landing.js'))
            .then(() => {
                if (window.AOS) {
                    window.AOS.init({ once: true });
                    window.addEventListener('load', window.AOS.refresh);
                }
            })
            .catch((err) => console.error('Erreur lors du chargement des scripts:', err));
    }, []);



    const [questionnaires, setQuestionnaires] = useState([]);
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    useEffect(() => {
        if (!userId) {
            navigate('/login'); 
        }
    }, [userId, navigate]);
    useEffect(() => {

      
        const fetchQuestionnaires = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/questionnaires/utilisateur/${userId}`);
                if (response.status === 200) {
                    setQuestionnaires(response.data);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des questionnaires:', error);
            }
        };

        fetchQuestionnaires();
    }, [userId]);

    const getVersionByNum = async (versionNum) => {
        try {
            const response = await axios.get(`${config.apiUrl}api/versionNUM/${versionNum}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            console.error('Erreur lors de la récupération de la version:', error);
            return null;
        }
        return null;
    };
    const handleCardClick = async (questionnaireId, versionNum) => {
        if (questionnaireId === null) {
            try {
                const version = await getVersionByNum(versionNum);
                if (version) {
                    const response = await axios.post(`${config.apiUrl}api/questionnaires`, {
                        etat: 'en cours',
                        utilisateur: userId,
                        version: version.id  
                    });
    
                    if (response.status === 201) {
                        const newQuestionnaireId = response.data.questionnaire.id;
                        localStorage.setItem('questionnaireId', newQuestionnaireId);
                        
                        navigate(`/chat?questionnaireId=${newQuestionnaireId}&message=${encodeURIComponent("Questionnaire V " + versionNum)}`);
                    }
                } else {
                    console.error('Version non trouvée.');
                }
            } catch (error) {
                console.error('Erreur lors de la création du questionnaire:', error);
            }
        } else {
            
            localStorage.setItem('questionnaireId', questionnaireId);
            navigate(`/chat?questionnaireId=${questionnaireId}&message=${encodeURIComponent("Questionnaire V " + versionNum)}`);
        }
    };
    
    
    return (
        <div>

<header>
                <button className="navbar-toggler d-xl-none d-inline navbar-menu-button" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#primaryMenu">
                    <span className="navbar-toggler-icon">
                        <i className="iconsax" icon-name="text-align-justify"></i>
                    </span>
                </button>

                <Link to="/">
                    <img src="../logoEM.png" className="img-fluid" alt="logo" style={{ width: '70%' }} />

                </Link>
                <nav className="header-nav-middle">
                    <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                        <div className="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
                            <div className="offcanvas-header navbar-shadow">
                                <h5 className="mb-0">Back</h5>
                                <button className="btn-close lead" type="button" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav">
                                    <li className="nav-item ">
                                        <Link className="nav-link" to="/">Accueil</Link>
                                    </li>
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/Chat">Chat</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/tutoriel">Aide et Tutoriel</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                
            </header>

            <section id="home" className="section-b-space home-section">
                <div className="container-fluid">
                    <div className="home-sec">
                        <div className="home-content">
                            <div>
                                <h2>Votre Guide Intelligent</h2>
                              
                                <div className="service-section ">
                                    {questionnaires.map((q) => (
                                        <div key={q.id} className="service-box" onClick={() => handleCardClick(q.id,q.Num_version)}>
                                            <div className="service-icon">
                                                <img src="../assets/svg/service/copy.svg" className="img-fluid outline-icon" alt="" />
                                                <img src="../assets/svg/service/copy-bold.svg" className="img-fluid bold-icon" alt="" />
                                            </div>
                                            <div className="service-content">
                                                <h3><img src="../assets/svg/service-title.svg" alt="effect" className="img-fluid" />Audit V {q.Num_version}</h3>
                                                <p>{q.etat}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                        <div className="home-img d-lg-flex d-none">
                            <img src="assets/images/landing/robot.png" className="img-fluid robot-img" alt="robot" />
                            <div className="round-effect">
                                <img src="assets/images/landing/round.gif" className="img-fluid " alt="robot" />
                            </div>
                            <ul className="home-effect">
                                <li><img src="assets/images/landing/effect.png" className="img-fluid effect-img" alt="" /><img
                                    src="assets/svg/service/search.svg" className="img-fluid outline-icon" alt="" /> Analyseur de Données
                                </li>
                                <li><img src="assets/images/landing/effect.png" className="img-fluid effect-img" alt="" /><img
                                    src="assets/svg/service/graph.svg" className="img-fluid outline-icon" alt="" /> Spécialiste en Recommandations
                                </li>
                                <li><img src="assets/images/landing/effect.png" className="img-fluid effect-img" alt="" /><img
                                    src="assets/svg/service/copy.svg" className="img-fluid outline-icon" alt="" /> Expert en Audit
                                </li>
                                <li><img src="assets/images/landing/effect.png" className="img-fluid effect-img" alt="" /><img
                                    src="assets/svg/service/text.svg" className="img-fluid outline-icon" alt="" /> Gestionnaire de Projets
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Welcome