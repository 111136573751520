
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react';
import TableQuestionCategorie from "./../modal/TableQuestionCategorie";
import './../App.css';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import RadarChart from './RadarChart';
import config from '../config';
import ProgressBar from './progress_bar';
import LogoutButton from './../button/LogoutButton';
import QuestionnaireHistory from './QuestionnaireHistory';
import introJs from 'intro.js';


function Chat() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const titre = queryParams.get('message');
    const questionnaireID = queryParams.get('questionnaireId');
    const [questions, setQuestions] = useState([]);
    const [questionsTable, setQuestionstable] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [categoryMap, setCategoryMap] = useState({});
    const targetDivRef = useRef(null);
    const [responsesByCategory, setResponsesByCategory] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const userid = localStorage.getItem('userId');
    const [versionId, setVersionId] = useState(null);


    // Fonction pour récupérer les statistiques
    const fetchCategoryStatistics = async (catMereId, questionnaireId) => {
        try {
            const response = await axios.get(`${config.apiUrl}api/categories_mere/${catMereId}/questionnaire/${questionnaireId}`);
            return response.data;
        } catch (error) {
            console.error('Erreur lors de la récupération des statistiques :', error);
            return null;
        }
    };
    useEffect(() => {
        if (questionnaireID) {
            // Fetch the versionId for the given questionnaireID
            async function fetchVersionId() {
                try {
                    const response = await fetch(`${config.apiUrl}api/questionnaires/${questionnaireID}/version`);
                    if (!response.ok) throw new Error("Failed to fetch version ID");
                    const data = await response.json();
                    setVersionId(data.version_id);
                } catch (err) {
                 //   setError(err.message);
                }
            }

            fetchVersionId();
        }
    }, [questionnaireID]);

    useEffect(() => {
        if (!questionnaireID) {
            navigate('/welcome'); // Redirect to the welcome page
        }

        if (versionId) {
            async function fetchCategories() {
                try {
                    const response = await fetch(`${config.apiUrl}api/categories_mere/${versionId}`);
                    if (!response.ok) throw new Error("Failed to fetch categories");
                    const categoriesMere = await response.json();

                    const categoryMap = {};
                    for (const category of categoriesMere) {
                        const stats = await fetchCategoryStatistics(category.id, questionnaireID);
                        categoryMap[category.id] = {
                            ...category,
                            subCategories: [],
                            stats
                        };
                    }

                    const subCategoryResponse = await fetch(`${config.apiUrl}api/categories/${versionId}`);
                    if (!subCategoryResponse.ok) throw new Error("Failed to fetch subcategories");
                    const subCategories = await subCategoryResponse.json();

                    for (const subCategory of subCategories) {
                        if (categoryMap[subCategory.categorie_mere_id]) {
                            categoryMap[subCategory.categorie_mere_id].subCategories.push(subCategory);
                        } else {
                            console.warn(`Category mère ID ${subCategory.categorie_mere_id} not found for subcategory ${subCategory.nom}`);
                        }
                    }

                    setCategoryMap(categoryMap);

                    const questionsResponse = await axios.get(`${config.apiUrl}api/questions/non_repondues/${questionnaireID}`);
                    const questionsResponseTable = await axios.get(`${config.apiUrl}api/questions`);
                    const questionsTable = questionsResponseTable.data;
                    const questions = questionsResponse.data;

                    for (const question of questions) {
                        for (const catMereId in categoryMap) {
                            const catMere = categoryMap[catMereId];
                            for (const subCategory of catMere.subCategories) {
                                if (question.categorie === subCategory.id) {
                                    subCategory.questions = subCategory.questions || [];
                                    subCategory.questions.push(question);
                                }
                            }
                        }
                    }

                    for (const question of questionsTable) {
                        for (const catMereId in categoryMap) {
                            const catMere = categoryMap[catMereId];
                            for (const subCategory of catMere.subCategories) {
                                if (question.categorie === subCategory.id) {
                                    subCategory.questions = subCategory.questions || [];
                                    subCategory.questions.push(question);
                                }
                            }
                        }
                    }

                    setQuestionstable(questionsTable);
                    setQuestions(questions);
                    setCurrentQuestion(questions[0]);

                    // Afficher la première question dans le chat après son chargement
                    if (questions.length > 0) {
                        const firstQuestion = questions[0];
                        let parentCategoryName = "Inconnue";
                        let subCategoryName = "Inconnue";
                        let coefficient = firstQuestion.coefficient || "N/A";

                        for (const catMereId in categoryMap) {
                            const catMere = categoryMap[catMereId];
                            if (catMere.subCategories) {
                                for (const subCategory of catMere.subCategories) {
                                    if (firstQuestion.categorie === subCategory.id) {
                                        subCategoryName = subCategory.nom;
                                        parentCategoryName = catMere.nom;
                                        break;
                                    }
                                }
                            }
                        }

                        appendMessage("left", `${parentCategoryName} > ${subCategoryName} <br/>${firstQuestion.contenu} <br/> Coefficient : ${coefficient}`);
                    }

                } catch (error) {
                    console.error(error);
                }
            }

            fetchCategories();
        }
    }, [questionnaireID, versionId]);

    const appendMessage = (side, text) => {
        const msgerChat = document.querySelector(".msger-chat");
        const msgHTML = `
            <div class="msg ${side}-msg">
                <div class="msg-bubble">
                    <div class="msg-text">${text}</div>
                </div>
            </div>
        `;

        msgerChat.insertAdjacentHTML("beforeend", msgHTML);

        // Faire défiler automatiquement vers le bas
        const lastMessage = msgerChat.lastElementChild;
        if (lastMessage) {
            lastMessage.scrollIntoView({ behavior: "smooth" });
        }
    };

    const fetchNextQuestion = (currentQuestionId) => {
        const currentIndex = questions.findIndex(q => q.code === currentQuestionId);
        if (currentIndex !== -1 && currentIndex < questions.length - 1) {
            return questions[currentIndex + 1];
        } else {
            return null;
        }
    };

    const submitScore = async (questionId, score) => {
        try {
            const response = await fetch(`${config.apiUrl}api/scores_q`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    questionnaire: questionnaireID,
                    question: questionId,
                    score: score,


                }),
            });

            if (!response.ok) throw new Error("Failed to submit score");

            return await response.json();
        } catch (error) {
            console.error(error);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const msgerInput = document.querySelector(".msger-input");
        const msgText = msgerInput.value;
        const score = parseInt(msgText);
        if (isNaN(score)) {
            appendMessage("left", "Veuillez entrer uniquement des chiffres.");
            return;
        }
        if (!msgText || !currentQuestion) return;
        if (score > (currentQuestion.coefficient || 0)) {
            appendMessage("left", `La note doit être inférieure ou égale à ${currentQuestion.coefficient}. Veuillez réessayer.`);
            return;
        }

        appendMessage("right", msgText);
        msgerInput.value = "";

        await submitScore(currentQuestion.code, score);

        // Mettre à jour les réponses par catégorie
        const updatedResponsesByCategory = { ...responsesByCategory };
        let currentCategoryId = null;
        for (const catMereId in categoryMap) {
            const catMere = categoryMap[catMereId];
            if (catMere.subCategories) {
                for (const subCategory of catMere.subCategories) {
                    if (currentQuestion.categorie === subCategory.id) {
                        currentCategoryId = catMereId;
                        updatedResponsesByCategory[catMereId] = updatedResponsesByCategory[catMereId] || {};
                        updatedResponsesByCategory[catMereId][subCategory.id] = (updatedResponsesByCategory[catMereId][subCategory.id] || 0) + 1;
                        break;
                    }
                }
            }
        }
        setResponsesByCategory(updatedResponsesByCategory);

        const nextQuestion = fetchNextQuestion(currentQuestion.code);
        if (nextQuestion) {
            setCurrentQuestion(nextQuestion);

            // Trouver la sous-catégorie et la catégorie mère de la prochaine question
            let parentCategoryName = "Inconnue";
            let subCategoryName = "Inconnue";
            let coefficient = nextQuestion.coefficient || "N/A";

            for (const catMereId in categoryMap) {
                const catMere = categoryMap[catMereId];
                if (catMere.subCategories) {
                    for (const subCategory of catMere.subCategories) {
                        if (nextQuestion.categorie === subCategory.id) {
                            subCategoryName = subCategory.nom;
                            parentCategoryName = catMere.nom;
                            break;
                        }
                    }
                }
            }

            // Afficher les statistiques des réponses pour la catégorie en cours
            let statsMessage = "";
            if (currentCategoryId) {
                const catMere = categoryMap[currentCategoryId];
                if (catMere.subCategories) {
                    for (const subCategory of catMere.subCategories) {
                        if (subCategory.id === currentQuestion.categorie) {
                            const answered = updatedResponsesByCategory[currentCategoryId][subCategory.id] || 0;
                            const total = subCategory.questions ? subCategory.questions.length : 0;
                            statsMessage = ` ${subCategory.nom} : ${answered} / ${total} questions répondues.<br/>`;
                            break;
                        }
                    }
                }
            }

            appendMessage("left", `${parentCategoryName} > ${subCategoryName} <br/>${statsMessage}  <br/> ${nextQuestion.contenu} <br/> Coefficient : ${coefficient}
                `);
        } else {
            appendMessage("left", "Plus de questions disponibles.");
        }
    };

    function startModalTutorial() {
        // Attendre que la modale soit complètement affichée
        setTimeout(() => {
            const intro = introJs();

            intro.setOptions({
                steps: [
                    {
                        element: document.querySelector(".Modal .modal-dialog"),
                        intro: "Voici le tableau des questions. Vous pouvez noter chaque question en fonction de son coefficient."
                    },
                    {
                        element: document.querySelector(".modal-footer .submit"),
                        intro: "Après avoir noté toutes les questions, cliquez sur 'Soumettre toutes les notes' pour enregistrer vos réponses."
                    }
                ],
                showStepNumbers: true,
                exitOnOverlayClick: true,
                showBullets: true,
                disableInteraction: false,
                nextLabel: "Suivant",
                prevLabel: "Précédent",
            });

            intro.start();
        }, 500); // On attend 500ms pour s'assurer que la modale est complètement rendue
    }

    const handleStartClick = (category) => {
        setSelectedCategory(category);
        setShowModal(true);
        startModalTutorial();
    };
    const handleCloseModal = () => {
        handleRefreshData();
        setShowModal(false);

    };

    const filteredQuestions = () => {
        if (!selectedCategory) return [];
        const subCategoryIds = selectedCategory.subCategories.map(subCat => subCat.id);
        return questionsTable
            .filter(q => subCategoryIds.includes(q.categorie))
            .map(q => ({
                ...q,
                subCategoryName: selectedCategory.subCategories.find(subCat => subCat.id === q.categorie)?.nom || 'Unknown'
            }));
    };

    const [synthese, setSynthese] = useState([]);

    const handleSyntheseClick = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}api/audit/synthese/${questionnaireID}`);
            setSynthese(response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération de la synthèse :", error);
        }
    };


    const handleRefreshData = async () => {
        try {
            async function fetchCategories() {
                try {
                    const response = await fetch(`${config.apiUrl}api/categories_mere/${versionId}`);
                    if (!response.ok) throw new Error("Failed to fetch categories");
                    const categoriesMere = await response.json();

                    const categoryMap = {};
                    for (const category of categoriesMere) {
                        const stats = await fetchCategoryStatistics(category.id, questionnaireID);
                        categoryMap[category.id] = {
                            ...category,
                            subCategories: [],
                            stats
                        };
                    }

                    const subCategoryResponse = await fetch(`${config.apiUrl}api/categories/${versionId}`);
                    if (!subCategoryResponse.ok) throw new Error("Failed to fetch subcategories");
                    const subCategories = await subCategoryResponse.json();

                    for (const subCategory of subCategories) {
                        if (categoryMap[subCategory.categorie_mere_id]) {
                            categoryMap[subCategory.categorie_mere_id].subCategories.push(subCategory);
                        } else {
                            console.warn(`Category mère ID ${subCategory.categorie_mere_id} not found for subcategory ${subCategory.nom}`);
                        }
                    }

                    setCategoryMap(categoryMap);

                    const questionsResponseTable = await axios.get(`${config.apiUrl}api/questions`);
                    const questionsTable = questionsResponseTable.data;




                    for (const question of questionsTable) {
                        for (const catMereId in categoryMap) {
                            const catMere = categoryMap[catMereId];
                            for (const subCategory of catMere.subCategories) {
                                if (question.categorie === subCategory.id) {
                                    subCategory.questions = subCategory.questions || [];
                                    subCategory.questions.push(question);
                                }
                            }
                        }
                    }

                    setQuestionstable(questionsTable);
                    setQuestions(questions);
                    setCurrentQuestion(questions[0]);



                } catch (error) {
                    console.error(error);
                }
            }
            await fetchCategories();
        } catch (error) {
            console.error("Erreur lors du rafraîchissement des données :", error);
        }
    };

    function startChatTutorial() {
        const intro = introJs();

        intro.setOptions({
            steps: [
                {
                    intro: "Audit en mode chat !"
                },
                {
                    element: document.querySelector("#chat"),
                    intro: "C'est la section de chat où vous pouvez commencer une conversation."
                },

                {
                    element: document.querySelector("[data-step='1-chat']"),
                    intro: "Ici, les messages de chat apparaîtront, les questions qui n'ont pas encore été répondues."
                },
                {
                    element: document.querySelector("[data-step='1-response']"),
                    intro: "C'est ici que vous pouvez entrer votre réponse et l'envoyer."
                }],
            showStepNumbers: true,
            exitOnOverlayClick: true,
            showBullets: true,
            disableInteraction: false,
            nextLabel: "Suivant",
            prevLabel: "Précédent",
        });

        intro.start();
    }
    function startRdarChartTutorial() {
        const intro = introJs();

        intro.setOptions({
            steps: [

                {
                    element: document.querySelector(".card-body .RadarChart"),
                    intro: "Voici le graphique radar qui représente les résultats de l'audit. Chaque axe montre la performance dans une catégorie différente."
                }

            ],
            showStepNumbers: true,
            exitOnOverlayClick: true,
            showBullets: true,
            disableInteraction: false,
            nextLabel: "Suivant",
            prevLabel: "Précédent",
        });

        intro.start();
    }

    function startTableQuestionTutorial() {
        const intro = introJs();

        intro.setOptions({
            steps: [
                {
                    intro: "Audit mode Grille !Cette section vous permet de sélectionner une catégorie et de commencer l'audit."
                },

                {
                    element: document.querySelector(".container.card .row"),
                    intro: "Ici, vous verrez les différentes catégories disponibles pour l'audit. Cliquez sur 'commencer' pour accéder aux questions de la catégorie choisie."
                }
            ],
            showStepNumbers: true,
            exitOnOverlayClick: true,
            showBullets: true,
            disableInteraction: false
        });

        intro.start();

        // Ajouter un écouteur pour la modale
        intro.oncomplete(() => {
            // Démarrer le tutoriel pour la modale une fois qu'elle est ouverte
            document.querySelectorAll('.select-plan').forEach(button => {
                button.addEventListener('click', startModalTutorial);
            });
        });
    }
    function startSyntheseTutorial() {
        const intro = introJs();

        intro.setOptions({
            steps: [

                {

                    intro: "Cette partie affiche la grille de synthèse de l'audit, où vous pouvez consulter le récapitulatif de l'audit."
                },
                {

                    intro: "Dans cette section, vous pouvez voir les notes et les pourcentages pour chaque catégorie et sous-catégorie. Le 'Total Général' vous donne un aperçu global de l'audit."
                }
            ],
            showStepNumbers: true,
            exitOnOverlayClick: true,
            showBullets: true,
            disableInteraction: false,
            nextLabel: "Suivant",
            prevLabel: "Précédent",
        });

        intro.start();
    }
    function startTutorial() {
        introJs().setOptions({
            steps: [
                {
                    intro: "Bienvenue sur la plateforme d'audit. Ce tutoriel vous guidera à travers les fonctionnalités principales de l'application."
                },
                {
                    element: document.querySelector(".nav-item[data-bs-title='Chat']"),
                    intro: "Voici le mode Chat. Vous pouvez commencer à répondre aux questions qui n'ont pas encore été répondues."
                },
                {
                    element: document.querySelector(".nav-item[data-bs-title='Subscription']"),
                    intro: "Voici le mode Grille ou les questions sont organisées en grilles par catégories mères."
                },
                {
                    element: document.querySelector(".nav-item[data-bs-title='synthese']"),
                    intro: "Voici la section de synthèse où vous pouvez voir une analyse graphique des résultats de l'audit."
                },

                {
                    element: document.querySelector(".nav-item[data-bs-title='Ratings']"),
                    intro: "Voici la section de notation où vous pouvez voir votre progression et soumettre vos réponses."
                },
                {
                    element: document.querySelector("i.ri-question-line"),
                    intro: "Cliquez ici pour accéder à l'aide et au tutoriel à tout moment."
                }
            ], showStepNumbers: true,
            exitOnOverlayClick: true,
            showBullets: true,
            disableInteraction: false,
            nextLabel: "Suivant",
            prevLabel: "Précédent",
        }).start();
    }

    return (
        <div>   <section className="chatting-wrapper pt-0">
            <nav className="navbar navbar-expand-md p-0">
                <button className="navbar-toggler d-none" type="button" data-bs-toggle="collapse"
                    data-bs-target="#mainnavbarNav" aria-controls="mainnavbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <i className="iconsax" data-icon="text-align-justify"></i>
                </button>
                <div className="collapse navbar-collapse" id="mainnavbarNav">
                    <div className="menu-panel">
                        <button data-bs-toggle="collapse" data-bs-target="#mainnavbarNav"
                            className="mainnav-close d-block d-md-none"><i className="iconsax" icon-name="close-circle" data-icon="close-circle"></i>
                        </button>
                        <Link to="/" className="logo-icon d-none d-md-flex">
                            <img src="../assets/svg/logo-icon.svg" className="img-fluid" alt="Logo de l'entreprise" />
                        </Link>
                        <ul className="nav nav-tabs menu-wrapper" id="myTab" role="tablist">
                            <li data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Chat" className="nav-item"
                                role="presentation">
                                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#chat" type="button"
                                    role="tab" aria-controls="chat" aria-selected="true"><i className="iconsax"
                                        data-icon="messages-2" icon-name="messages-2"></i>
                                    <span>Chat</span> </button>
                            </li>

                            <li data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Subscription"
                                className="nav-item" role="presentation" onClick={handleRefreshData}>
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#subscription" type="button"
                                    role="tab" aria-controls="subscription" aria-selected="false"><i className="iconsax"
                                        data-icon="crown-2" icon-name="grid-table-edit" ></i> <span>{titre}</span></button>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="synthese" className="nav-item"
                                role="presentation" onClick={handleSyntheseClick}>
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#synthese" type="button"
                                    role="tab" aria-controls="synthese" aria-selected="false"><i className="iconsax" data-icon="question-message
                            " icon-name="presentation-chart"></i> <span>synthese</span> </button>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="History" className="nav-item"
                                role="presentation">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#history" type="button"
                                    role="tab" aria-controls="history" aria-selected="false"> <img className="iconsaximage img-fluid outline-icon"
                                        src="../assets/svg/radar-chart.png"
                                        alt=""
                                        style={{ filter: 'invert(1)', transform: 'scale(1.7)' }} />
                                    <span>radar chart</span> </button>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Ratings" className="nav-item"
                                role="presentation">
                                <button type="button" className="btn btn-primary nav-link" data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"><i className="iconsax" data-icon="activity-chart" icon-name="activity-chart"></i> <span>
                                        Progress Bar
                                    </span>
                                </button>

                            </li>
                           
                            <li 
                               >
                                <button className="nav-link" 
                                    type="button" role="tab" aria-controls="historique" aria-selected="false">
                                   <i
                                    className="ri-question-line"
                                    style={{ color: 'white',transform: 'scale(1.5)' }}
                                    onClick={startTutorial}
                                ></i>
                                </button>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="chat" role="tabpanel" aria-labelledby="chat" tabIndex="0">
                    <div className="main-wrapper p-0">
                        <div className="fixed-header">
                            <div className="d-flex align-items-center gap-2">
                                <button className="navbar-toggler d-md-none d-block" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#mainnavbarNav" aria-controls="mainnavbarNav" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <i className="iconsax" data-icon="text-align-justify" icon-name="text-align-justify"></i>
                                </button>
                                <Link to="/" className="logo-icon d-flex d-md-none">
                                    <img src="../assets/svg/logo-icon.svg" className="img-fluid" alt="Logo" />
                                </Link>
                                <h3 id="targetDiv" ref={targetDivRef}>Audit SI</h3>
                                <i
                                    className="ri-question-line"
                                    style={{ color: 'white' }}
                                    onClick={startChatTutorial}
                                ></i>
                            </div>
                            <LogoutButton />

                        </div>
                        <div className="main-chat">
                            <div className="no-chat">
                                <div>
                                    <img src="../assets/svg/no-chat.svg" className="img-fluid" alt="No chat" />
                                    <h3>Demarrer l'audit</h3>
                                </div>
                            </div>
                            <div className="msger-chat" style={{ padding: '0 170px 120px' }}></div>
                            <form className="msger-inputarea" onSubmit={handleFormSubmit} style={{ left: '280px' }}>
                                <button className="navbar-toggler d-lg-none d-block msger-send-btn" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <i className="iconsax" data-icon="text-align-justify" icon-name="text-align-justify"></i>
                                </button>
                                <input type="text" className="msger-input" placeholder="..." />
                                <Link to="" className="scan-icon"><i className="iconsax" data-icon="scan-1" icon-name="scan-1"></i></Link>
                                <Link to="" className="mic-icon"><i className="iconsax" data-icon="mic-1" icon-name="mic-1"></i></Link>
                                <button type="submit" className="msger-send-btn"><i className="iconsax" data-icon="send-1" icon-name="send-1"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history" tabIndex="0">
                    <div className="main-wrapper p-0">
                        <div className="fixed-header">
                            <div className="d-flex align-items-center gap-2">
                                <button className="navbar-toggler d-md-none d-block" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#mainnavbarNav" aria-controls="mainnavbarNav" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <i className="iconsax" data-icon="text-align-justify" icon-name="text-align-justify"></i>
                                </button>
                                <Link to="/" className="logo-icon d-flex d-md-none">
                                    <img src="../assets/svg/logo-icon.svg" className="img-fluid" />
                                </Link>
                                <h3>SYNOPTIQUE DE L'AUDIT</h3>
                                <i
                                    className="ri-question-line"
                                    style={{ color: 'white' }}
                                    onClick={startRdarChartTutorial}
                                ></i>
                            </div>
                            <LogoutButton />
                        </div>
                        <div className="main-section">
                            <div className="container card p-0">
                                <div className="card-header">
                                    <h3 className="text-white">SYNOPTIQUE DE L'AUDIT
                                    </h3>

                                </div>
                                <div className="card-body px-sm-4 px-3" style={{ paddingLeft: '30% !important' }}>
                                    <RadarChart idQuestionnaire={questionnaireID} />

                                </div>
                                <div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="subscription" role="tabpanel" aria-labelledby="subscription" tabIndex="0">
                    <div className="main-wrapper p-0">
                        <div className="fixed-header">
                            <div className="d-flex align-items-center gap-2">
                                <button className="navbar-toggler d-md-none d-block" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#mainnavbarNav" aria-controls="mainnavbarNav" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <i className="iconsax" data-icon="text-align-justify"></i>
                                </button>
                                <Link to="/" className="logo-icon d-flex d-md-none">
                                    <img src="../assets/svg/logo-icon.svg" className="img-fluid" />
                                </Link>
                                <h3>{titre}</h3>
                                <i
                                    className="ri-question-line"
                                    style={{ color: 'white' }}
                                    onClick={startTableQuestionTutorial}
                                ></i>
                            </div>
                            <LogoutButton />
                        </div>
                        <div className="main-section">
                            <div className="container card p-0">
                                <div className="card-header">
                                    <h3 className="text-white">Veuillez sélectionner votre catégorie et commencez le questionnaire</h3>

                                </div>
                                <div className="card-body px-sm-4 px-3">
                                    <div className="row justify-content-center">
                                        {Object.keys(categoryMap).map(catMereId => {
                                            const catMere = categoryMap[catMereId];

                                            return (
                                                <div className="col-xl-4 col-md-6 col-12" key={catMereId}>
                                                    <div className="card inner-card">
                                                        <div className="card-header">
                                                            <img src="../assets/svg/pricing/weekly.svg" className="img-fluid" alt="" />
                                                            <h4 className="text-white mb-0">{catMere.nom}</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <h3>Détails  : </h3>
                                                            <ul>
                                                                <li>Nombre total de questions : {catMere.stats.total_questions}</li>
                                                                <li>Questions répondues : {catMere.stats.questions_repondues}</li>
                                                                <li>Questions restantes : {catMere.stats.questions_restantes}</li>
                                                            </ul>
                                                            <button
                                                                className='select-plan'
                                                                onClick={() => handleStartClick(catMere)}
                                                            >

                                                                commencer
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        <TableQuestionCategorie
                                            show={showModal}
                                            handleClose={handleCloseModal}
                                            selectedCategory={selectedCategory}
                                            filteredQuestions={filteredQuestions}
                                            questionnaireID={questionnaireID}

                                        />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="synthese" role="tabpanel" aria-labelledby="synthese" tabIndex="0">
                    <div className="main-wrapper p-0">
                        <div className="fixed-header">
                            <div className="d-flex align-items-center gap-2">
                                <button className="navbar-toggler d-md-none d-block" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#mainnavbarNav" aria-controls="mainnavbarNav" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <i className="iconsax" data-icon="text-align-justify"></i>
                                </button>
                                <Link to="/" className="logo-icon d-flex d-md-none">
                                    <img src="../assets/svg/logo-icon.svg" className="img-fluid" />
                                </Link>
                                <h3>Synthèse</h3>
                                <i
                                    className="ri-question-line"
                                    style={{ color: 'white' }}
                                    onClick={startSyntheseTutorial}
                                ></i>
                            </div>
                            <LogoutButton />
                        </div>
                        <div className="faq-section main-section">
                            <div className="container card p-0">
                                <div className="card-header">
                                    <h3 className="text-white title-basic aos-init aos-animate" data-aos="fade-up"
                                        data-aos-duration="1000" data-aos-delay="100">Grille de Synthèse d'Audit</h3>
                                </div>
                                <div className="card-body px-sm-4 px-3">

                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>Catégorie Mère</th>
                                                <th>Nom</th>
                                                <th>Note</th>
                                                <th>Pourcentage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {synthese.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className="table-category-mere">
                                                        <td rowSpan={item.categories.length + 1}>
                                                            {item.categorieMere}
                                                        </td>
                                                        <td><strong>Total</strong></td>
                                                        <td>{item.note}</td>
                                                        <td>{item.pourcentageCM}</td>
                                                    </tr>
                                                    {item.categories.map((categorie, idx) => (
                                                        <tr key={idx}>
                                                            <td>{categorie.nom}</td>
                                                            <td>{categorie.note}</td>
                                                            <td>{categorie.pourcentage}</td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                            <tr>
                                                <td colSpan="2"><strong>Total Général</strong></td>
                                                <td>{synthese.length > 0 ? synthese[synthese.length - 1].note : 'N/A'}</td>
                                                <td>{synthese.length > 0 ? synthese[synthese.length - 1].pourcentageCM : 'N/A'}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <div className="modal rating-modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Une barre de progression pour suivre votre avancement dans le questionnaire.</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="iconsax" data-icon="close-square"></i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {/* Insérer la barre de progression ici */}
                            <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                                <ProgressBar idQuestionnaire={questionnaireID} versionID={versionId} />
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="modal-submit m-0" data-bs-dismiss="modal">ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <section>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade" id="historique" role="tabpanel" aria-labelledby="historique-tab" tabIndex="0">


                            <div className="card-body px-sm-4 px-3" style={{ paddingLeft: '110px' }}>

                                <div className="card-body px-sm-4 px-3">

                                    {/*   <QuestionnaireHistory userId={userid} />*/}
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Chat
