import React, { useEffect, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import axios from 'axios';
import config from '../config';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const OrganisationStats = () => {
  const [domains, setDomains] = useState([]); 
  const [selectedDomain, setSelectedDomain] = useState(''); 
  const [versions, setVersions] = useState([]); 
  const [selectedVersion, setSelectedVersion] = useState(''); 
  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    
    axios.get(`${config.apiUrl}api/organisation_stats`)
      .then(response => {
        const data = response.data;
        setDomains(Object.keys(data)); 
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des domaines", error);
      });
  }, []);

  const handleDomainChange = (event) => {
    const domain = event.target.value;
    setSelectedDomain(domain); 
    fetchVersions(domain); 
  };

  const fetchVersions = (domain) => {
   
    axios.get(`${config.apiUrl}api/organisation_stats?domain=${domain}`)
      .then(response => {
        const data = response.data[domain];
        const versions = Object.keys(data); 
        setVersions(versions);
        setSelectedVersion('');
        setChartData(null); 
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des versions du domaine", error);
      });
  };

  const handleVersionChange = (event) => {
    const version = event.target.value;
    setSelectedVersion(version); 
    fetchDomainStats(selectedDomain, version); 
  };

  const fetchDomainStats = (domain, version) => {
   
    axios.get(`${config.apiUrl}api/organisation_stats?domain=${domain}`)
      .then(response => {
        const data = response.data[domain]; 
        setChartData(formatChartData(data[version])); 
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des statistiques du domaine et de la version", error);
      });
  };

  const formatChartData = (data) => {
    const labels = Object.keys(data); 
    const datasets = [
      {
        label: 'Moyenne des Scores', 
        data: labels.map(category => data[category].average || 0), 
        fill: true,
        backgroundColor: 'rgb(59, 237, 178, 0.2)',
        borderColor: 'rgb(59, 237, 178)',
      }
    ];

    return {
      labels: labels,
      datasets: datasets, 
    };
  };
  
  return (
    <div>
   

      <div style={{ padding: "20px", backgroundColor: "#2c3e50", borderRadius: "10px" }}>
        <label htmlFor="domain-select" style={{ marginLeft: "10%" }}>Sélectionnez un domaine :</label>
        <select id="domain-select" value={selectedDomain} onChange={handleDomainChange}>
          <option value="">--Choisissez un domaine--</option>
          {domains.map((domain) => (
            <option key={domain} value={domain}>
              {domain}
            </option>
          ))}
        </select>

        {selectedDomain && (
          <>
            <label htmlFor="version-select" style={{ marginLeft: "13%" }}>Sélectionnez une version :</label>
            <select
              id="version-select"
              value={selectedVersion}
              onChange={handleVersionChange}
              disabled={versions.length === 0}
              style={{ marginTop: "10px" }}
            >
              <option value="">--Choisissez une version--</option>
              {versions.map((version) => (
                <option key={version} value={version}>
                  {version}
                </option>
              ))}
            </select>
          </>
        )}
      </div>


      {/* Afficher le graphique radar uniquement si les données sont disponibles */}
      {chartData && (
        <Radar
          data={chartData}
          options={{
            
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: `Statistiques pour le domaine : ${selectedDomain}, Version : ${selectedVersion}`,
              },
            },
            scales: {
              r: {
                beginAtZero: true,
                suggestedMin: 0,
                suggestedMax: 100,
              },
            },
            
          }}
        />
      )}
    </div>
  );
};

export default OrganisationStats;
