import React, { useState,useEffect } from 'react';
import { Modal, Table, Button, Form } from 'react-bootstrap';
import './../App.css';
import axios from 'axios';
import config from '../config';
import introJs from 'intro.js';

const TableQuestionCategorie = ({ show, handleClose, selectedCategory, filteredQuestions ,questionnaireID }) => {
    // State to store scores
    const [scores, setScores] = useState({});
   
    useEffect(() => {
        const fetchData = async () => {
            if (questionnaireID === null) return;

            try {
                const responsesResponse = await axios.get(`${config.apiUrl}api/scores_q/questionnaire/${questionnaireID}`)
              
                const formattedScores = responsesResponse.data.reduce((acc, curr) => {
                    acc[curr.question] = curr.score;
                    return acc;
                }, {});

                setScores(formattedScores);
               
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error);
            }
        };

        fetchData();
    }, [questionnaireID]);
 
    const groupedQuestions = filteredQuestions().reduce((acc, question) => {
        const subCategoryName = question.subCategoryName;
        if (!acc[subCategoryName]) {
            acc[subCategoryName] = [];
        }
        acc[subCategoryName].push(question);
        return acc;
    }, {});


    const sortedSubCategories = Object.keys(groupedQuestions).sort((a, b) => {
        const aId = selectedCategory.subCategories.find(subCat => subCat.nom === a)?.id || 0;
        const bId = selectedCategory.subCategories.find(subCat => subCat.nom === b)?.id || 0;
        return aId - bId;
    });


    const handleScoreChange = (questionId, value) => {
        setScores({
            ...scores,
            [questionId]: value
        });

    };

    const submitScores = async () => {
      
        try {
            const promises = Object.entries(scores).map(([questionId, score]) =>
                fetch(`${config.apiUrl}api/scores_q`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        questionnaire: questionnaireID,
                        question: questionId,
                        score: score,
                        
                    }),
                })
            );

            const responses = await Promise.all(promises);

           
            if (responses.every(response => response.ok)) {
                alert('Les scores ont été enregistrés avec succès !');
                handleClose(); 
            } else {
                alert('Échec de l’enregistrement de certains scores.');
            }
            } catch (error) {
                alert('Une erreur s’est produite lors de l’enregistrement des scores.');
            }
            
    };

    const startModalTutorial = () => {
        // Wait for the modal to fully render
        setTimeout(() => {
            const intro = introJs();

            intro.setOptions({
                steps: [
                    {
                        element: document.querySelector(".modal-dialog"),
                        intro: "Voici le tableau des questions. Vous pouvez noter chaque question en fonction de son coefficient."
                    },
                    {
                        element: document.querySelector(".modal-footer .select-plan"),
                        intro: "Après avoir noté toutes les questions, cliquez sur 'Soumettre toutes les notes' pour enregistrer vos réponses."
                    }
                ],
                showStepNumbers: true,
                exitOnOverlayClick: true,
                showBullets: true,
                disableInteraction: false
            });

            intro.start();
        }, 500); // Wait 500ms to ensure the modal is fully rendered
    };
      
    return (
        <Modal dialogClassName="custom-modal-width" show={show} onHide={handleClose}>

            <Modal.Header closeButton>
            <Modal.Title>Questions pour {selectedCategory?.nom}</Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <Table bordered >
                    <thead>
                        <tr>
                            <th></th>
                            <th>Question</th>
                            <th>Coefficient</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedSubCategories.map(subCategoryName => (
                            <React.Fragment key={subCategoryName}>
                                <tr>
                                    <td colSpan="4"><strong>{subCategoryName}</strong></td>
                                </tr>
                                {groupedQuestions[subCategoryName]
                                    .sort((a, b) => a.id - b.id)
                                    .map(question => (
                                        <tr key={question.id}>
                                            <td>{question.id}</td>
                                            <td>{question.contenu}</td>
                                            <td>{question.coefficient}</td>
                                            <td>
                                                <Form.Control className='input-group'
                                                  type="number"
                                                    min="0"
                                                    max={question.coefficient}
                                                    value={scores[question.code] || ''}
                                                    onChange={(e) => handleScoreChange(question.code, e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
               
                
                
                                    <button  className="no-select-plan select-plan" onClick={handleClose}>
                    Fermer
                </button>
                <button className="no-select-plan select-plan submit" onClick={submitScores}>
                    Soumettre toutes les notes
                </button>
                                    
                                
            </Modal.Footer>

        </Modal>
    );
};

export default TableQuestionCategorie;
