import React from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
import Footer from './../components/Footer'

const Home = () => {

    useEffect(() => {

        /**=====================
             Laptop Animation
        ==========================**/

        // laptop rotate effect

        const element = document.getElementById("laptop-img");

        let rotationDegree = 60;
        window.addEventListener("scroll", () => {
            rotationDegree = Math.max(0, 60 - (window.scrollY / window.innerHeight) * 120);
            element.style.transform = `rotateX(${rotationDegree}deg)`;
        });


        // effect object rotate
        window.addEventListener("scroll", () => {
            const rotationAngle = window.pageYOffset * 0.15;
            const rotateObjects = document.querySelectorAll(".rotate-effect");
            rotateObjects.forEach((object) => {
                object.style.transform = `rotate(${rotationAngle}deg)`;
            });
        });

    }, []);

    /*  <!-- header sticky js -->*/
    useEffect(() => {

        /*===================
       header sticky js
       =======================*/
        window.addEventListener('scroll', function () {
            var header = document.querySelector('header');

        /*    if (window.pageYOffset > 500) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }*/
        });

    }, []);

    return (
        <div>


            <header>
                <button className="navbar-toggler d-xl-none d-inline navbar-menu-button" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#primaryMenu">
                    <span className="navbar-toggler-icon">
                        <i className="iconsax" icon-name="text-align-justify"></i>
                    </span>
                </button>

                <Link to="/">
                    <img src="../logoEM.png" className="img-fluid" alt="logo" style={{ width: '70%' }} />

                </Link>
                <nav className="header-nav-middle">
                    <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                        <div className="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
                            <div className="offcanvas-header navbar-shadow">
                                <h5 className="mb-0">Back</h5>
                                <button className="btn-close lead" type="button" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav">
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/">Accueil</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/Chat">Chat</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/tutoriel">Aide et Tutoriel</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <Link to="/login" className="btn btn-theme d-sm-inline-block d-none" data-cursor="pointer">
                    <span>Connectez-vous</span>
                </Link>
            </header>



            <section className="home-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12" style={{ paddingTop: '16%' }}>
                            <div className="home-content">
                                <div className="bg-effect">
                                    <img src="../assets/images/home/home-bg.gif" className="img-fluid bg-gif" alt="" />
                                    <img src="../assets/svg/home/1.png" className="img-fluid effect1 rotate-effect" alt="" />
                                    <img src="../assets/svg/home/2.svg" className="img-fluid effect2 rotate-effect" alt="" />
                                </div>
                                <div>
                                    <h1>UTILISEZ NOTRE OUTIL D' <div className="title-effect">
                                        <img src="../assets/images/title-effect.png" alt="" /><span>AUDIT SI</span>
                                    </div>POUR SIMPLIFIER VOTRE GESTION INFORMATIQUE</h1>
                                    <p>
                                        Notre outil d'audit SI est une solution puissante qui utilise des algorithmes avancés pour analyser et optimiser les systèmes d'information de votre entreprise.
                                        Développé pour accompagner les utilisateurs dans l'audit de leurs infrastructures numériques, cet outil vous permet de gagner du temps et de l'énergie tout en garantissant un niveau constant de précision et d'efficacité.
                                    </p>

                                </div>
                            </div>
                            <div className="home-laptop px-md-0 px-3">
                                <div className="laptop-sec position-relative">
                                    <div className="hand-sec">
                                        <img src="../assets/images/home/hand.png" className="img-fluid left-hand" alt="hand" />
                                        <img src="../assets/images/home/hand.png" className="img-fluid right-hand" alt="hand" />
                                        <img src="../assets/images/home/finger.png" className="img-fluid left-finger" alt="hand" />
                                        <img src="../assets/images/home/finger.png" className="img-fluid right-finger" alt="hand" />
                                    </div>
                                    <img src="../assets/images/home/laptop.png" id="laptop-img" className="img-fluid laptop-img" alt="laptop" />
                                </div>
                                <div className="home-info">
                                    <ul className="info-list">
                                        <li>Posez toutes vos questions</li>
                                        <li>Générez des rapports d'audit</li>
                                        <li>Analysez et optimisez vos SI</li>
                                    </ul>
                                    <ul className="star-rating">
                                        <li><i className="ri-star-fill"></i></li>
                                        <li><i className="ri-star-fill"></i></li>
                                        <li><i className="ri-star-fill"></i></li>
                                        <li><i className="ri-star-fill"></i></li>
                                        <li><i className="ri-star-fill"></i></li>
                                    </ul>
                                    <h4>Une méthode pour auditer vos systèmes d'information 10 fois plus rapidement.</h4>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="about-section section-b-space section-mb-space">
                <div className="bg-effect" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500"></div>
                <div className="container">
                    <div className="row g-md-5 g-4">
                        <div className="col-lg-6 order-lg-0 order-1">
                            <div className="about-content">
                                <div>
                                    <div className="title">
                                        <span className="number-pattern">01.</span>
                                        <h2 className="text-white">Un Outil Innovant pour les Audits SI : Analyse en Un Clic.</h2>
                                    </div>
                                    <p>La réalisation d'audits SI efficaces est cruciale pour évaluer la performance et la sécurité des systèmes d'information des entreprises. Grâce à notre application web, obtenez des analyses détaillées en un seul clic, vous permettant d'identifier rapidement les points forts et les axes d'amélioration de vos infrastructures numériques.</p>
                                    <ul>
                                        <li><img src="../assets/svg/tick.svg" className="img-fluid" alt="tick" />Améliorez la résilience de vos systèmes avec des recommandations précises et personnalisées.</li>
                                        <li><img src="../assets/svg/tick.svg" className="img-fluid" alt="tick" />Engagez vos équipes dans une démarche proactive grâce à des rapports clairs et accessibles.</li>
                                        <li><img src="../assets/svg/tick.svg" className="img-fluid" alt="tick" />Optimisez votre stratégie informatique avec des graphiques et des données visuelles qui facilitent la prise de décision.</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src="../assets/svg/character/1.svg" className="img-fluid" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-section right-version section-b-space section-mb-space">
                <div className="bg-effect" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500"></div>
                <div className="container">
                    <div className="row g-md-5 g-4">
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src="../assets/svg/character/3.svg" className="img-fluid" alt="about" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content">
                                <div>
                                    <div className="title">
                                        <span className="number-pattern">02.</span>
                                        <h2>La Ressource Clé pour l'Optimisation du Contenu d'Audit SI</h2>
                                    </div>
                                    <p>"Libérez le potentiel de votre audit SI : Personnalisez vos rapports pour répondre aux attentes de vos équipes et améliorer la performance de vos systèmes d'information !"</p>
                                    <ul>
                                        <li><img src="../assets/svg/tick.svg" className="img-fluid" alt="tick" />Obtenez des informations précieuses sur les besoins spécifiques de votre entreprise en matière de sécurité et d'efficacité des SI.</li>
                                        <li><img src="../assets/svg/tick.svg" className="img-fluid" alt="tick" />Créez des rapports d'audit captivants qui parlent directement aux décideurs et aux équipes techniques.</li>
                                        <li><img src="../assets/svg/tick.svg" className="img-fluid" alt="tick" />Identifiez les points faibles critiques et les défis auxquels vos systèmes d'information sont confrontés, et proposez des solutions concrètes et adaptées.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="info-section section-b-space">
                <div className="container">
                    <div className="info-box" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="100">
                        <div className="hand-effect d-md-block d-none">
                            <img src="../assets/svg/hand.svg" className="img-fluid left-hand" alt="hand" />
                            <img src="../assets/svg/hand.svg" className="img-fluid right-hand" alt="hand" />
                        </div>
                        <h2>Prêt à <span>passer <img src="../assets/svg/title-effect.svg" className="img-fluid" alt="title-effect" /></span>
                            à la vitesse supérieure ?</h2>
                        <p>Avec notre outil d'audit SI révolutionnaire, exploitez le potentiel des technologies de pointe pour optimiser votre gestion informatique. Adoptez dès aujourd'hui l'avenir et laissez notre solution redéfinir ce qui est possible pour vous.</p>
                        <ul>
                            <li><img src="../assets/svg/tick.svg" className="img-fluid" alt="tick" />Accès illimité aux rapports d'audit</li>
                            <li><img src="../assets/svg/tick.svg" className="img-fluid" alt="tick" />Obtenez des analyses détaillées sur chaque aspect de votre SI</li>
                            <li><img src="../assets/svg/tick.svg" className="img-fluid" alt="tick" />Conseiller rapide pour vous accompagner</li>
                            <li><img src="../assets/svg/tick.svg" className="img-fluid" alt="tick" />Explorez plus de 15 catégories d'analyse</li>
                        </ul>
                        <a data-cursor="pointer" className="btn-arrow" href="">
                            <div className="icon-arrow"><i className="iconsax" icon-name="arrow-up"></i></div>Contactez-nous dès maintenant
                        </a>

                    </div>
                </div>
            </section>

            <Footer />

            <div className="tap-to-top-box hide">
                <button className="tap-to-top-button"><i className="iconsax" icon-name="chevron-up"></i></button>
            </div>

        </div>

    )
}

export default Home