import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from './../logout';
import "./../pages/dashboardAdmin.css";


const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        const success = await logout();
        if (success) {
            // Rediriger vers la page de connexion ou une autre page appropriée
            navigate('/login');
        } else {
            // Gérez les erreurs si nécessaire
            console.error('La déconnexion a échoué.');
        }
    };

    return (

        <div className="header-option">

            <a className="premium-btn" data-cursor="pointer" href="#" rel="noreferrer noopener" onClick={handleLogout} style={{ color: '#8dfddf82' }}>
                <img src="../assets/images/logout.png" alt="" style={{ width: '33px', height: 'auto' }} />
            </a>

        </div>


    );
};

export default LogoutButton;
