import React, { useEffect, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import axios from 'axios';
import config from '../config';
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RadarChart = ({ idQuestionnaire }) => {
  const [chartData, setChartData] = useState({
    aspectRatio: 1,
    layout: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: 'rgb(59, 237, 178, 0.2)', 
        borderColor: 'rgb(59, 237, 178)', 
        borderWidth: 1,
      },
    ],
  });

  const [chartOptions, setChartOptions] = useState({
    aspectRatio: 2,
    scales: {
      r: {
        angleLines: {
          color: '#F5F5F5',
        },
       
        ticks: {
          display: false,
          color: '#F5F5F5', // Couleur des chiffres (ticks)
          font: {
            size: 14, // Taille des chiffres (ticks)
          },
        },
        pointLabels: {
          color: 'rgb(255, 255, 255, 0.5)', // Couleur des labels autour du radar chart
          font: {
            size: 12, // Taille des labels
          },
        },
        plugins: {
          legend: {
            align: 'start', // Options: 'start', 'center', 'end'

            position: 'right', // Options: 'top', 'left', 'bottom', 'right'
            labels: {
              padding: 10,
              color: 'white',
              font: {
                size: 12,
              },
            },
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'white', // Couleur des labels de la légende
        },
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}api/audit/synthese/${idQuestionnaire}`);
        const data = response.data;

        const filteredData = data.filter(item => item.categorieMere !== 'TOTAL');

        const labels = filteredData.map(item => item.categorieMere);
        const values = filteredData.map(item => parseFloat(item.pourcentageCM));

        setChartData({
          labels: labels,
          datasets: [
            {
              label: 'Categories',
              data: values,
              backgroundColor: 'rgb(59, 237, 178, 0.2)', // Couleur de fond maintenue
              borderColor: 'rgb(59, 237, 178)', // Couleur des bordures maintenue
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [idQuestionnaire]);

  return (
    <div >
      <Radar data={chartData} options={chartOptions} />
    </div>
  );
  
};

export default RadarChart;
