import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import config from '../config';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const Statistics = () => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.apiUrl}api/statistics`);
        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  
  const chartData = {
    labels: stats.map(stat => stat.domaine_activite), 
    datasets: [
      {
        label: 'Pourcentage',
        data: stats.map(stat => parseFloat(stat.pourcentage.replace('%', ''))), 
        backgroundColor: 'rgba(75, 192, 192, 0.2)', 
        borderColor: 'rgba(75, 192, 192, 1)', 
        borderWidth: 1, 
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Statistiques des Activités',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Domaine d\'Activité',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Pourcentage',
        },
        ticks: {
          beginAtZero: true,
          callback: (value) => `${value}%`, // Ajoute le symbole '%' sur l'axe des Y
        },
      },
    },
  };

  return (
    <div>
 
      {Array.isArray(stats) && stats.length > 0 ? (
        <>
          <Bar data={chartData} options={chartOptions} />
          
        </>
      ) : (
        <p>Unable to fetch statistics.</p>
      )}
    </div>
  );
};

export default Statistics;
