import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Chat from './pages/Chat';
import Tutoriel from './pages/Tutoriel';
import DashboardAdmin from './pages/DashboardAdmin';
import Welcome from './pages/Welcome';
import PrivateRoute from './components/PrivateRoute';
import Contact from './pages/Contact';


function App() {
    return (
        <BrowserRouter>
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/chat' element={<PrivateRoute element={Chat} />} />
            <Route path='/tutoriel' element={<PrivateRoute element={Tutoriel} />} />
            <Route path='/dashboardAdmin' element={<PrivateRoute element={DashboardAdmin} />} />
            <Route path='/welcome' element={<PrivateRoute element={Welcome} />} />
            <Route path='/'element={<Home />} />
            <Route path='/contact'element={<Contact />} />
        </Routes>
    </BrowserRouter>
    );
}

export default App;
