
export const logout = async () => {
    try {
        localStorage.clear();
        return true;
    } catch (err) {
        console.error('Erreur lors de la déconnexion:', err);
        return false;
    }
};
