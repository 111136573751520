import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './../pages/dashboardAdmin.css';

const UserModal = ({ show, handleClose, handleChange, handleSubmit, form, organisations }) => {
    return (
        <Modal show={show} onHide={handleClose} className="Modal">
            <Modal.Header closeButton>
                <Modal.Title>{form.id ? 'Modifier l\'utilisateur' : 'Créer un utilisateur'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="auth-form" onSubmit={handleSubmit}>
                    <Form.Group className="form-group">
                        <Form.Label>Login</Form.Label>
                        <Form.Control 
                            className="form-control" 
                            type="text" 
                            name="login" 
                            value={form.login} 
                            onChange={handleChange} 
                            required 
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control 
                            className="form-control" 
                            type="password" 
                            name="mdp" 
                            value={form.mdp} 
                            onChange={handleChange} 
                            required 
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Organisation</Form.Label>
                        <Form.Select 
                            className="form-control" 
                            name="organisation" 
                            value={form.organisation} 
                            onChange={handleChange} 
                            required
                        >
                            <option value="">Sélectionnez une organisation</option>
                            {organisations.map(org => (
                                <option key={org.id} value={org.id}>
                                    {org.nom}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Rôle</Form.Label>
                        <Form.Control 
                            className="form-control" 
                            type="text" 
                            name="role" 
                            value={form.role} 
                            onChange={handleChange} 
                            required 
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            className="form-control" 
                            type="email" 
                            name="email" 
                            value={form.email} 
                            onChange={handleChange} 
                            required 
                        />
                    </Form.Group>
                    <Button type="submit" className="btn-solid w-100 text-center mt-3">
                        Enregistrer
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UserModal;
