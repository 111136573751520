import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
const ProgressBar = ({ idQuestionnaire , versionID}) => {
  const [progress, setProgress] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProgressData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${config.apiUrl}api/progress/${idQuestionnaire}/version/${versionID}`);
        const data = response.data;
  
        setProgress(data.progress_percentage);
        setAnsweredQuestions(data.answered_questions);
        setTotalQuestions(data.total_questions);
        setError(null);
      } catch (error) {
        console.error('Error fetching progress data:', error);
        setError('Unable to fetch progress data.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchProgressData();
  }, [idQuestionnaire, versionID]);
  
  


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <div className="progress-bar-container" style={{ width: '100%', backgroundColor: '#f3f3f3', borderRadius: '5px' }}>
        <div
          className="progress-bar"
          style={{
            width: `${progress}%`,
            backgroundColor: '#3BEDB2',
            height: '24px',
            borderRadius: '5px',
          }}
        ></div>
      </div>
      <p>{answeredQuestions}/{totalQuestions} questions répondues</p>
    </div>
  );
};

export default ProgressBar;
