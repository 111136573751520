import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './../pages/dashboardAdmin.css';

const ImportFileModal = ({ show, handleClose, handleFileChange, handleSubmit }) => {
    return (
        <Modal show={show} onHide={handleClose} className="Modal">
            <Modal.Header closeButton>
                <Modal.Title>Import Questionnaire</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="auth-form" onSubmit={handleSubmit}>
                    <Form.Group className="form-group">
                        <Form.Label>Select File</Form.Label>
                        <Form.Control
                            className="form-control"
                            type="file"
                            name="file"
                            accept=".csv"
                            onChange={handleFileChange}
                            required
                        />
                    </Form.Group>
                    <Button type="submit" className="btn-solid w-100 text-center mt-3">
                        Upload
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ImportFileModal;
