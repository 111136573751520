import React from 'react'
import { Link } from 'react-router-dom';
import Footer from './../components/Footer'
function Tutoriel() {
  return (
    <div>
      <header>
        <button className="navbar-toggler d-xl-none d-inline navbar-menu-button" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#primaryMenu">
          <span className="navbar-toggler-icon">
            <i className="iconsax" icon-name="text-align-justify"></i>
          </span>
        </button>

        <Link to="/">
          <img src="../logoEM.png" className="img-fluid" alt="logo" style={{ width: '70%' }} />

        </Link>
        <nav className="header-nav-middle">
          <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
            <div className="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
              <div className="offcanvas-header navbar-shadow">
                <h5 className="mb-0">Back</h5>
                <button className="btn-close lead" type="button" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav">
                  <li className="nav-item ">
                    <Link className="nav-link" to="/">Accueil</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/Chat">Chat</Link>
                  </li>
                  <li className="nav-item active">
                    <Link className="nav-link" to="/tutoriel">Aide et Tutoriel</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <Link to="/login" className="btn btn-theme d-sm-inline-block d-none" data-cursor="pointer">
          <span>Connectez-vous</span>
        </Link>
      </header>




      <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="breadcrumb-content">
                <div>
                  <h2><img src="../assets/images/breadcrumb-title.png" className="img-fluid"
                    alt="title-effect" />Tutoriels
                  </h2>
                  <p><i className="ri-subtract-line"></i>Découvrez comment utiliser notre application web dédiée à l'audit des systèmes informatiques. Ce tutoriel vous guidera à travers les étapes pour commencer, compléter et analyser vos audits.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-inline-block d-none">
              <div className="breadcrumb-img">
                <img src="../assets/svg/contact-vector.svg" className="img-fluid" alt="service" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="core-feature section-b-space">
        <div className="container">
          <div className="title text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <h2>Présentation de la plateforme d'audit</h2>
            <h3>Obtenez des recommandations personnalisées pour améliorer vos scores d'audit.</h3>
          </div>
          <div className="core-feature">
            <div className="feature-box text-end" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="400">
              <img src="assets/images/landing/arrow.svg" className="img-fluid" alt="" />
              <h3>1. Analyse de l'Audit</h3>
              <p> Après la complétion de l'audit, notre système analyse vos réponses et identifie les points faibles ainsi que les domaines nécessitant des améliorations.</p>
            </div>
            <img src="assets/images/landing/laptop.png" data-aos="zoom-in" data-aos-duration="1000"
              data-aos-delay="100" className="img-fluid laptop-img" alt="" />
            <div className="feature-box" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="1000">
              <img src="assets/images/landing/arrow.svg" className="img-fluid" alt="" />
              <h3>2. Recommandations Personnalisées</h3>
              <p>Sur la base de l'analyse, le système génère des recommandations spécifiques pour chaque domaine identifié comme nécessitant des améliorations. Ces recommandations sont conçues pour vous aider à améliorer vos pratiques et à obtenir un score plus élevé.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="ratio2_1">
        <div className="container">
          <div className="row g-5">
          <div className="col-12" style={{ 

 borderRadius: '8px', 
 padding: '30px', 
 margin: '30px', 
}}>
             <div className="blog-listing" style={{ display: 'flex', flexWrap: 'nowrap' }}>

                <div className="blog-img">
                  <img src="../assets/images/tuto/welcome.PNG" className="img-fluid  bg-img" alt="audit-tutorial" />
                  <label>Connexion et Sélection</label>
                </div>
                <div className="blog-content">
                  <a data-cursor="pointer" className="main-title" href="#connexion-selection">Connexion</a>
                  <p>Après la connexion, l'utilisateur doit sélectionner la version d'audit à laquelle il souhaite participer.
                    <br></br>(Il est important de noter qu'une organisation ne peut avoir qu'un seul représentant.)
                    <br></br> - <strong>Statut "non encore"</strong> : Si l'utilisateur n'a pas encore commencé cette version de l'audit.
                    <br></br> - <strong>Statut "en cours"</strong> : Si l'utilisateur a commencé mais n'a pas terminé cette version.
                  </p>


                </div>
              </div>
            </div>

            <div className="col-12" style={{ 

 borderRadius: '8px', 
 padding: '30px',  
 margin: '30px', 
}}>
              <div className="blog-listing" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <div className="blog-img">
                  <img src="../assets/images/tuto/chat1.PNG" className="img-fluid bg-img" alt="audit-tutorial" />
                  <label>Démarrage de l'audit</label>
                </div>
                <div className="blog-content">
                  <a data-cursor="pointer" className="main-title" href="#demarrage-audit">Démarrage de l'audit</a>
                  <p>Une fois la version de l'audit sélectionnée, l'utilisateur peut choisir entre deux modes de réponse :
                    <br></br>
                    <ul>
                      <li><strong>Mode Chat</strong> : Les questions non encore répondues sont affichées sous forme de catégories et sous-catégories, avec le nombre de questions répondues, le total, le coefficient, et la question correspondante.</li>
                      <br></br> <li><strong>Mode Grille</strong> (par catégories) : Les questions sont organisées en grilles par catégories mères, et l'utilisateur doit répondre à toutes les questions avant de soumettre ses réponses.</li>
                    </ul>
                  </p>


                </div>
              </div>
              <div className="blog-listing" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <div className="blog-img">
                  <img src="../assets/images/tuto/chat2.PNG" className="img-fluid bg-img" alt="audit-tutorial" />

                </div>
                <div className="blog-content">

                  <p>
                    Dans les deux modes, la note doit être un chiffre compris entre 0 et le coefficient de la question.
                  </p>


                </div>
              </div>
            </div>

            <div className="col-12" style={{ 
   
    borderRadius: '8px', 
    padding: '30px',  
    margin: '30px',  
}}>
              <div className="blog-listing" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <div className="blog-img">
                  <img src="../assets/images/tuto/progressBar.PNG" className="img-fluid bg-img" alt="audit-tutorial" />
                  <label>Réponse aux questions</label>
                </div>
                <div className="blog-content">
                  <a data-cursor="pointer" className="main-title" href="#analyse-progression">Analyse et visualisation des résultats</a>
                  <p> L'utilisateur peut suivre sa progression grâce à une barre de progression</p>


                </div>
              </div>
              <div className="blog-listing" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <div className="blog-img">
                  <img src="../assets/images/tuto/synthese.PNG" className="img-fluid bg-img" alt="audit-tutorial" />

                </div>
                <div className="blog-content">
                  <p> L'utilisateur peut également obtenir une analyse en fonction de ses réponses, même si l'audit n'est pas encore terminé, avec une représentation graphique sous forme de radar chart.</p>
                </div>
              </div>
              <div className="blog-listing" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <div className="blog-img">
                  <img src="../assets/images/tuto/radarChart.PNG" className="img-fluid bg-img" alt="audit-tutorial" />
                </div>
                <div className="blog-content">
                  <p> avec une représentation graphique sous forme de radar chart.</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>


   <Footer></Footer>
    </div>
  )
}

export default Tutoriel