import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import  './login.css';
import config from '../config';

function loginClick() {
    var loginTab = document.getElementById('login-tab');
    loginTab.click();
}
function signupClick() {
    var signupTab = document.getElementById('signup-tab');
    signupTab.click();
}
function Login() {
    localStorage.clear();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate  = useNavigate ();
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.apiUrl}api/auth/login`, {
                email,
                password,
            });
            console.log('Réponse reçue:', response.data); 
        
            const { token, id, role } = response.data.utilisateur;
            console.log('ID utilisateur:', id); 
          
         
            localStorage.setItem('authToken', token);
            localStorage.setItem('userId', id);
            localStorage.setItem('userRole', role);
    
            if (role === 'admin') {
                navigate('/dashboardAdmin');
            } else {
                navigate('/welcome');
            }
        } catch (err) {
            setError('Invalid email or password');
        }
    };
    

    const [name, setName] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [organisation, setOrganisation] = useState('');
    const [organisations, setOrganisations] = useState([]);
    const [success, setSuccess] = useState('');

    useEffect(() => {
        
        const fetchOrganisations = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/organisations`);
                setOrganisations(response.data);
                console.log(setOrganisation);
            } catch (err) {
                setError('Erreur lors de la récupération des organisations');
            }
        };

        fetchOrganisations();
    }, []);

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Les mots de passe ne correspondent pas');
            return;
        }
        try {
            const response = await axios.post(`${config.apiUrl}api/auth/signup`, {
                name,
                email,
                password,
                organisation,
            });
            setSuccess('Utilisateur créé avec succès');
            setError('');
        } catch (err) {
            setError('Erreur lors de la création de l\'utilisateur');
        }
    };
   
  return (
    <div>
      
    <section className="login-section">
        <a href="index.html"><img src="../assets/images/logo.svg" className="img-fluid logo-auth"/></a>
        <div className="row m-0">
            <div className="col-lg-7 d-lg-inline-block d-none p-0">
                <div className="login-animation">
                    <img src="../assets/svg/auth/1.svg" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100"
                        className="img-fluid img-base" alt=""/>
                    <img src="../assets/svg/auth/2.svg" data-aos="zoom-in-up" data-aos-duration="1000"
                        data-aos-delay="1000" className="img-fluid img-light" alt=""/>
                    <div className="img-face" data-aos-delay="2500" data-aos="flip-left" data-aos-easing="ease-out-cubic"
                        data-aos-duration="1000"> <img src="../assets/svg/auth/3.svg" className="img-fluid" alt=""/></div>
                </div>
            </div>
            <div className="col-xxl-4 col-lg-5 ms-auto p-0">
                <div className="login-box">
                    <div>
                        <h2>Bienvenue<span> EM-SE</span></h2>
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="login-tab" data-bs-toggle="tab"
                                    data-bs-target="#login-tab-pane" type="button" role="tab"
                                    aria-controls="login-tab-pane" aria-selected="true">Se connecter</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="signup-tab" data-bs-toggle="tab"
                                    data-bs-target="#signup-tab-pane" type="button" role="tab"
                                    aria-controls="signup-tab-pane" aria-selected="false">S'inscrire</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="login-tab-pane" role="tabpanel"
                                aria-labelledby="login-tab" tabindex="0">
                                 <form className="auth-form" onSubmit={handleLogin}>
                                   <div className="mb-3 form-group">
                                       <i className="iconsax mail" data-icon="mail" icon-name="mail"></i>
                                       <label htmlFor="emailid" className="form-label">Email</label>
                                       <input
                                           type="email"
                                           placeholder="Entrez votre adresse email"
                                           className="form-control"
                                           id="emailid"
                                           value={email}
                                           onChange={(e) => setEmail(e.target.value)}
                                       />
                                   </div>
                                   <div className="mb-2 form-group">
                                       <i className="iconsax lock-2" data-icon="lock-2" icon-name="lock-2"></i>
                                       <label htmlFor="password" className="form-label">Mot de passe</label>
                                       <input
                                           placeholder="Entrez votre mot de passe"
                                           type="password"
                                           className="form-control"
                                           id="password"
                                           value={password}
                                           onChange={(e) => setPassword(e.target.value)}
                                       />
                                   </div>
                                  {/*<div className="text-end">
                                       <a data-cursor="pointer" href="reset-password.html">Forget Password?</a>
                                   </div>*/}
                                   <button type="submit" className="btn-solid w-100& text-center mt-3">Se connecter</button>
                                   {error && <p className="error-message">{error}</p>}
                                   <h4 className="text-title text-center mt-2">Vous n'avez pas de compte ? <a
                                       data-cursor="pointer"
                                       onClick={() => console.log('Redirect to signup')}
                                       href="javascript:void(0)"
                                   >Inscrivez-vous</a></h4>
         
                                </form>
                            </div>
                            <div className="tab-pane fade" id="signup-tab-pane" role="tabpanel" aria-labelledby="signup-tab"
                                tabindex="0">
                                <form className="auth-form" onSubmit={handleSignUp}>
            <div className="mb-3 form-group">
                <i className="iconsax user-1" data-icon="user-1" icon-name="user-1"></i>
                <label htmlFor="name" className="form-label">Login</label>
                <input 
                    type="text" 
                    placeholder="Entrez votre login" 
                    className="form-control" 
                    id="name" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                />
            </div>
            <div className="mb-3 form-group">
                <i className="iconsax" icon-name="mail"></i>
                <label htmlFor="emailid" className="form-label">Email</label>
                <input 
                    type="email" 
                    placeholder="Entrez votre adresse e-mail" 
                    className="form-control"
                    id="emailid" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                />
            </div>
            <div className="mb-3 form-group">
                <i className="iconsax" icon-name="lock-2"></i>
                <label htmlFor="password" className="form-label">Mot de passe</label>
                <input 
                    placeholder="Entrez votre mot de passe" 
                    type="password" 
                    className="form-control"
                    id="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                />
            </div>
            <div className="mb-3 form-group">
                <i className="iconsax" icon-name="lock-2"></i>
                <label htmlFor="password1" className="form-label">Confirmez le mot de passe</label>
                <input 
                    placeholder="Entrez votre mot de passe" 
                    type="password" 
                    className="form-control"
                    id="password1" 
                    value={confirmPassword} 
                    onChange={(e) => setConfirmPassword(e.target.value)} 
                />
            </div>
            <div className="mb-3 form-group">
            <i className="iconsax ri-building-line"></i>
                <label htmlFor="organisation" className="form-label">Organisation</label>
                <select
                    id="organisation"
                    className="form-control"
                    value={organisation}
                    onChange={(e) => setOrganisation(e.target.value)}
                >
                    <option value="">Sélectionnez une organisation</option>
                    {organisations.map((org) => (
                        <option key={org.id} value={org.id}>{org.nom}</option>
                    ))}
                </select>
            </div>
            <button type="submit" className="btn-solid w-100 text-center mt-4">Inscrire</button>
            {error && <p style={{color: 'red'}}>{error}</p>}
            {success && <p style={{color: 'green'}}>{success}</p>}
            <h4 className="text-title text-center mt-2">
            Vous avez déjà un compte  <a data-cursor="pointer" href="javascript:void(0)" onClick={loginClick}>Se connecter</a>
            </h4>
        </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Login